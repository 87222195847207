<template>
  <div>
    <b-modal
      id="personal-away-entitlement-modal"
      title="Apply New Away"
      title-class="h4 font-weight-bold"
      lazy
      size="lg"
      hide-footer
    >
      <PersonalAwayEntitlementApplyAwayForm
        class="mx-2"
        :is-busy="isBusy"
        @submit-clicked="submitForm"
      >
      </PersonalAwayEntitlementApplyAwayForm>
    </b-modal>
  </div>
</template>

<script>
import PersonalAwayEntitlementApplyAwayForm from "@/modules/personal/components/away/entitlement/modal/PersonalAwayEntitlementApplyAwayForm";
import dayjs from "dayjs";
export default {
  name: "PersonalAwayEntitlementModal",
  components: { PersonalAwayEntitlementApplyAwayForm },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
    };
  },
  methods: {
    /**
     * Submit form via store.
     *
     * @param form
     */
    submitForm(form) {
      this.isBusy = true;
      this.$store
        .dispatch("submitPersonalAwayApplicationForm", {
          form: {
            from_date: dayjs().format(),
            to_date: dayjs().format(),
            employee_id: this.form.teacher_id ?? this.form.staff_id,
            id: this.form.away.school_away.id,
            remark: form.remark,
          },
        })
        .then((res) => {
          this.$bvModal.msgBoxOk(this.$t("ALERT.SUCCESS"));
          this.$emit("formSubmitted", res);
        })
        .catch(() => {
          this.$bvModal.msgBoxOk(this.$t("ALERT.ERROR"), {
            okVariant: "danger",
          });
        })
        .finally(() => (this.isBusy = false));
    },
  },
};
</script>

<style scoped></style>
